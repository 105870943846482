import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Msg1 from '../../components/Msg1/Msg1'
import './WelcomeContainer.css';
import messages  from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { userModel,messageWindowModel } from "../../Interfaces";
import { RootState } from "../../storage/redux/store";

//<Msg1 buttonslistprops={msg1.buttonslistprops} title={msg1.title} text={msg1.text}/> 

const WelcomeContainer = () => {
    const containeraction = useSelector((state: any) => state.globalContainerStore.containeraction);
    const containerurl = useSelector((state: any) => state.globalContainerStore.containerurl);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userData: userModel = useSelector(
      (state: RootState) => state.userAuthStore
    );

   useEffect(() => {
    navigate(containerurl);
  }, [containerurl]);

    // WhatDoWeDo
    const WhatDoWeDo_Close = () => {
      if(userData.id){
        dispatch(updatecontainerurl('/media')); 
        navigate("/media");
      }else{
        dispatch(updatecontaineraction('ExploreLoginCreate'))
      }
    }
    const TermsLink = () => {
      dispatch(updatecontaineraction(''));
      dispatch(updatecontainerurl('/terms'));  
    }
    // From ExploreLoginCreate
    const ExploreLoginCreate_Create = () => {
      dispatch(updatecontaineraction(''));
      dispatch(updatecontainerurl('/subscribe'));      
    }
    const ExploreLoginCreate_Explore = () => {
      console.log('reLoginCreate_Explor');
      dispatch(updatecontaineraction(''));
      dispatch(updatecontainerurl('/explore'));        
    }
    const ExploreLoginCreate_Login = () => {
      dispatch(updatecontaineraction(''));
      dispatch(updatecontainerurl('/login'));  
    }

    switch(containeraction) {
        case 'ExploreLoginCreate':
          messages[containeraction].buttonslistprops[0].onclick = ExploreLoginCreate_Create;          
          messages[containeraction].buttonslistprops[1].onclick = ExploreLoginCreate_Explore;          
          messages[containeraction].buttonslistprops[2].onclick = ExploreLoginCreate_Login;          
          return (
            <Msg1 buttonsprops={messages[containeraction]}/> 
          );
        default:
          return (
           <WhatDoWeDo WhatDoWeDo_Close={WhatDoWeDo_Close} TermsLink={TermsLink}/>
          );
      }
}
    
export default WelcomeContainer;