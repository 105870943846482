import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
};

export const reportsContainerSlice = createSlice({
    name: "reportsContainer",
    initialState:initialState,
    reducers:{
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },

    },
});

export const {updateaction} =  reportsContainerSlice.actions;
export const reportsContainerReducer = reportsContainerSlice.reducer;