import Headermenu from '../Headermenu/Headermenu'
import AboutUs from './img/AboutUs.svg';
import ArtLogo from './img/ArtLogo.png';
import './Header.css'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updatecontainerurl,updatecontaineraction,setlogout } from '../../storage/redux/slice/globalContainerSlice';

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import {
  emptyUserState,
  setLoggedInUser,
} from "../../storage/redux/slice/userAuthSlice";
//import { SD_Roles } from "../../Utility/SD";

// <Link to=''> </Link>

const Header = () => {
    const logged = useSelector((state: any) =>  state.globalContainerStore.logged); 
    const containerurl = useSelector((state: any) => state.globalContainerStore.containerurl);
    const [count, setCount] = useState(0);
    const [currentUrl, setCurrentUrl] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
      );
    

    //useEffect(() => {
      //  setCurrentUrl(containerurl) ;
      //});

    useEffect(() => {
        navigate(currentUrl) ;
      }, [count]);

      const handleLogout = () => {
        localStorage.removeItem("token");
        dispatch(setLoggedInUser({ ...emptyUserState }));
        dispatch(updatecontainerurl('/login'));
        setCurrentUrl('/login');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
      }


    const LogOutButton = () => {
        dispatch(updatecontainerurl('/login'));
        setCurrentUrl('/login');  
        dispatch(setlogout())  ;      
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    }
    const LogoClick = () => {
        dispatch(updatecontainerurl('/'));
        dispatch(updatecontaineraction(''));
        setCurrentUrl('/');  
        setCount(prev=>prev+1);
    }
    
    const TermsLink = () => {
        dispatch(updatecontaineraction(''));
        dispatch(updatecontainerurl('/terms'));  
        setCurrentUrl('/terms');  
        setCount(prev=>prev+1);        
      }

    const AccountButton = () => {
        dispatch(updatecontainerurl('/account'));
        setCurrentUrl('/account');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    }
    const MediaButton = () => {
        dispatch(updatecontainerurl('/media'));
        setCurrentUrl('/media');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    }
    const ReportsButton = () => {
        dispatch(updatecontainerurl('/reports'));
        setCurrentUrl('/reports');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    }        
    const AboutButton = () => {
        dispatch(updatecontainerurl('/about'));
        setCurrentUrl('/about');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    }    

/*
    if(document.getElementById('headermenudiv')!== null){
        if(userData){
            document.getElementById('headermenudiv').style.display = 'flex';        
        }else{
            document.getElementById('headermenudiv').style.display = 'None';
        }
    }
*/
console.log("userData.id");
console.log(userData.id);
    return (
            <header>
                    <div className="logo-dropdown">

                        <img alt="" className="logoimage" src={ArtLogo} onClick={LogoClick}/>

                            <div className="dropdown-content-logo">
                                <Link to='/'>
                                    <div className="aligned">
                                        <img alt=""  src={AboutUs} />
                                        <div className="gmenutitle gmenutitle_aboutus">
                                            <span>About us</span>
                                            <div className="bar"></div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className="aligned" onClick={TermsLink}>
                                    <img alt=""  src={AboutUs} />
                                        <div className="gmenutitle gmenutitle_terms" >
                                            <span >Terms and<br/>Conditions</span>
                                            <div className="bar"></div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className="aligned">
                                        <img alt=""  src={AboutUs} />
                                        <div className="gmenutitle gmenutitle_news">
                                            <span>News</span>
                                            <div className="bar"></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    </div>
                {userData.id ? (
                <div id="headermenudiv" className="headermenu" style={{ display:  'flex'}}>
                    <Headermenu  logoutonclick={handleLogout} aboutclick={AboutButton} reportsclick={ReportsButton} mediaclick={MediaButton} accountclick={AccountButton}/>
                </div>
                ) : (
                    <div id="headermenudiv" className="headermenu" style={{ display:  'none'}}>
                    <Headermenu logoutonclick={handleLogout} aboutclick={AboutButton} reportsclick={ReportsButton} mediaclick={MediaButton} accountclick={AccountButton}/>
                </div>
                )}
            </header>        
    );
}

export default Header;