import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/subscribeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import CreateTrial from '../../components/CreateTrial/CreateTrial';
import './SubscribeContainer.css';
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SubscribeContainer = () => {
  const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
  const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(containerurl);
  }, [containerurl]);

  const CreateTrial_Create = () => {
    //Create account with 30 day trial
    dispatch(updatecontaineraction('TrialReady'));
    //dispatch(updatecontainerurl('/login'));        
  }  

  const TrialReady_Login = () => {
    dispatch(updatecontaineraction(''));
    dispatch(updatecontainerurl('/login'));        
  }  

    switch(containeraction) {
      case 'TrialReady':
        messages[containeraction].buttonslistprops[0].onclick = TrialReady_Login;          
        return (
          <Msg1 buttonsprops={messages[containeraction]}/> 
        );
      default:
        return (
          <CreateTrial onClick={CreateTrial_Create} />
        );
    }
}
    
export default SubscribeContainer;