import './Footer.css'
import  TrashFull from '../../assets/img/misc/TrashFull.svg';
import StatusBackgroundSmall from '../../assets/img/misc/StatusBackgroundSmall.svg';
import StatusIconAlert from '../../assets/img/misc/StatusIconAlert.svg';
import RedNumber23 from '../../assets/img/misc/RedNumber23.png';
import StatusBackgroundMedium from '../../assets/img/misc/StatusBackgroundMedium.svg';
import Portfolio from '../../assets/img/misc/Portfolio.svg';
import Frequency from '../../assets/img/misc/Frequency.svg';
import AlertWeb from '../../assets/img/misc/AlertWeb.svg';
import AlertSMS from '../../assets/img/misc/AlertSMS.svg';
import Alertemail from '../../assets/img/misc/Alertemail.svg';
import StatusBackgroundLarge from '../../assets/img/misc/StatusBackgroundLarge.svg';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import React from "react";
import { useNavigate } from "react-router-dom";
import { userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import {
  emptyUserState,
  setLoggedInUser,
} from "../../storage/redux/slice/userAuthSlice";


const Footer = () => {
    const logged = useSelector((state: any) => state.globalContainerStore.logged); 
    const containerurl = useSelector((state: any) => state.globalContainerStore.containerurl);
    const [count, setCount] = useState(0);
    const [currentUrl, setCurrentUrl] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        navigate(currentUrl) ;
      }, [count]);
    
      const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
      );
/*
    if(document.getElementById('footericonsdiv')!== null){
        if(logged){
            document.getElementById('footericonsdiv').style.display = 'flex';
            document.getElementById('footertrashdiv').style.display = 'flex';        
        }else{
            document.getElementById('footericonsdiv').style.display = 'none';
            document.getElementById('footertrashdiv').style.display = 'none';                
        }
    }
*/
    const TrashButton = () => {
        dispatch(updatecontainerurl('/trash'));
        setCurrentUrl('/trash');  
        dispatch(updatecontaineraction(''));
        setCount(prev=>prev+1);
    } 

    return (
        <footer>
            <div className="emptydiv1">
            </div>
            {userData.id ? (
            <div id="footericonsdiv" className="footericons" style={{ display:  'flex'}}>
                <div className="twoimagesdiv marginright10">

                        <img alt="" className="underimage" src={StatusBackgroundSmall}/>
                        <img alt="" className="alertimage" src={StatusIconAlert} />
                        {/*<img alt="" className="rednumber23image" src={RedNumber23} />*/}

                </div>
                <div className="twoimagesdiv marginright10">
                        <img alt="" className="underimage" src={StatusBackgroundMedium} />
                        <img alt="" className="portfolioimage" src={Portfolio} />
                        {/*<span className="portfolioimagetext">4 / 50</span>*/}
                </div>
                <div className="twoimagesdiv marginright10">
                    <img alt="" className="underimage" src={StatusBackgroundMedium} />
                    <img alt="" className="frequencyimage" src={Frequency} />
                    <span  className="frequencyimagetext">Daily</span>
                </div>
                <div className="twoimagesdiv">
                    <img alt="" className="underimage" src={StatusBackgroundLarge} />
                    <img alt="" className="alertemailimage" src={Alertemail} />
                    <img alt="" className="alertsmsimage" src={AlertSMS} />
                    <img alt="" className="alertwebimage" src={AlertWeb}/>
                </div>
            </div>
            ) : (
                <div id="footericonsdiv" className="footericons" style={{ display:  'none'}}>
                <div className="twoimagesdiv marginright10">

                        <img alt="" className="underimage" src={StatusBackgroundSmall}/>
                        <img alt="" className="alertimage" src={StatusIconAlert} />
                        {/*<img alt="" className="rednumber23image" src={RedNumber23} />*/}

                </div>
                <div className="twoimagesdiv marginright10">
                        <img alt="" className="underimage" src={StatusBackgroundMedium} />
                        <img alt="" className="portfolioimage" src={Portfolio} />
                        {/*<span className="portfolioimagetext">4 / 50</span>*/}
                </div>
                <div className="twoimagesdiv marginright10">
                    <img alt="" className="underimage" src={StatusBackgroundMedium} />
                    <img alt="" className="frequencyimage" src={Frequency} />
                    <span  className="frequencyimagetext">Daily</span>
                </div>
                <div className="twoimagesdiv">
                    <img alt="" className="underimage" src={StatusBackgroundLarge} />
                    <img alt="" className="alertemailimage" src={Alertemail} />
                    <img alt="" className="alertsmsimage" src={AlertSMS} />
                    <img alt="" className="alertwebimage" src={AlertWeb}/>
                </div>
            </div>    
            )}
            {userData.id ? (
                <div id="footertrashdiv" className="footertrash" style={{ display:  'flex'}} onClick={TrashButton}> 
                {/*
                    <img alt="Something in TrashFull" title="Something in TrashFull"  src={TrashFull}/>
                */}
                </div>
            ) : (
                <div id="footertrashdiv" className="footertrash" style={{ display:  'none'}} onClick={TrashButton}> 
                {/*
                    <img alt="Something in TrashFull" title="Something in TrashFull"  src={TrashFull}/>
                */}
                </div>                
            )}                
        </footer>
    );
}

export default Footer;