import React, { useState } from "react";
import { useLoginUserMutation } from "../../Apis/authApi";
import { inputHelper } from "../helper";
import { apiResponse, userModel } from "../../Interfaces";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedInUser } from "../../storage/redux/slice/userAuthSlice";
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import BrownButtonReg from '../BrownButtons/BrownButtonReg';
import BrownButtonReg2 from '../BrownButtons/BrownButtonReg2';
//import { MainLoader } from "../Components/Page/Common";

function Login() {
  const [error, setError] = useState("");
  const [loginUser] = useLoginUserMutation();
  //const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    userName: "",
    password: "",
  });

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //setLoading(true);
    if(userInput.userName !=="" && userInput.password !==""){
        const response: apiResponse = await loginUser({
        userName: userInput.userName,
        password: userInput.password,
        });
        if (response.data) {
        const { token } = response.data.result;
        const { fullName, id, email, role }: userModel = jwt_decode(token);
        localStorage.setItem("token", token);
        dispatch(setLoggedInUser({ fullName, id, email, role }));
        dispatch(updatecontainerurl('/media')); 
        navigate("/media");
        } else if (response.error) {
            dispatch(updatecontaineraction('WrongCredentials'));
            dispatch(updatecontainerurl('/logincontainer'));  
            navigate("/logincontainer")
        //setError(response.error.data.errorMessages[0]);
        }
    }
    //setLoading(false);
  };

  return (
    <div className="container">
        <div className="divtitle">
            <span className="spantitle">Log In</span>
        <div>&nbsp;</div>
        </div>
        <div className="divtext">
            <p>Please enter your credentials:</p>
        </div>
        <div className="buttons">
        <div >
            <div className="loginrow">
                <BrownButtonReg2  title={"Email address"} onClick={""} />&nbsp;&nbsp;
                <input
                 type="email"
                 id="loginemail"
                 name="userName"
                 value={userInput.userName}
                 onChange={handleUserInput} 
                 pattern=".+@globex\.com"
                 size={50} 
                 required/>
            </div>   
            <div className="loginrow">
                <BrownButtonReg2 title={"Password"} onClick={""}/>&nbsp;&nbsp;
                <input
                 type="password"
                 id="loginpassword"
                 name="password"
                 value={userInput.password}
                 onChange={handleUserInput}
                 size={50}
                 required/>                    
            </div>
            <div className="loginrow">
                <BrownButtonReg   title={"Log In"} onClick={handleSubmit}/>  
            </div> 
        </div> 
        </div>
    </div> 
);

}

export default Login;