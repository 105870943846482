import { Routes, Route } from "react-router-dom";
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import WhatDoWeDo from '../components/WhatDoWeDo/WhatDoWeDo'
import WelcomeContainer from '../containers/WelcomeContainer/WelcomeContainer';
import LoginContainer from '../containers/LoginContainer/LoginContainer';
import SubscribeContainer from '../containers/SubscribeContainer/SubscribeContainer';
import PayContainer from '../containers/PayContainer/PayContainer';
import ExploreContainer from '../containers/ExploreContainer/ExploreContainer';
import MediaContainer from '../containers/MediaContainer/MediaContainer';
import TrashContainer from '../containers/TrashContainer/TrashContainer';
import ReportsContainer from '../containers/ReportsContainer/ReportsContainer';
import AccountContainer from '../containers/AccountContainer/AccountContainer';
import CreateTrial from '../components/CreateTrial/CreateTrial';
import './App.css'
import Subscription from "../components/Subscription/Subscription";
import Bsubscription from "../components/Subscription/Bsubscription";
import Terms from "../components/Terms/Terms";
import About from "../components/About/About";
import TrialReady from "../components/Messages/TrialReady";
import Login from "../components/Login/Login";
import UploadImage from "../components/UploadImage/UploadImage";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { userModel } from "../Interfaces";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from  "../storage/redux/slice/userAuthSlice" ;

//                        <Route path="/" element={<WelcomeContainer/>} />    <Route path="/" element={<CreateTrial />} />
//<Route path="/subscribe" element={<SubscribeContainer/>} /> MediaContainer
const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const localToken = localStorage.getItem("token");
        if (localToken) {
          const { fullName, id, email, role }: userModel = jwt_decode(localToken);
          dispatch(setLoggedInUser({ fullName, id, email, role }));
        }
      }, []);

    return (
        <>
            <Header />
                <div className="bg-main">
                    <div className="flexbox">
                    <Routes>
                        <Route path="/explore" element={<ExploreContainer/>} />
                        <Route path="/subscribe" element={<SubscribeContainer/>} />
                        <Route path="/pay" element={<PayContainer/>} />                        
                        <Route path="/login" element={<Login/>} />
                        <Route path="/media" element={<MediaContainer/>} />
                        <Route path="/logincontainer" element={<LoginContainer/>} />
                        <Route path="/trash" element={<TrashContainer/>} />
                        <Route path="/reports" element={<ReportsContainer/>} />
                        <Route path="/account" element={<AccountContainer/>} />
                        <Route path="/welcome" element={<WelcomeContainer />} />                        
                        <Route path="/about" element={<About />} />    
                        <Route path="/terms" element={<Terms />} /> 
                        <Route path="/trialready" element={<TrialReady />} />                         
                        <Route path="/" element={<WelcomeContainer />} />
                    </Routes>
                    </div>
                </div>
            <Footer />        
        </>
    );
}

export default App;