import './LoginInput.css';
import React from 'react';
import BrownButtonReg2 from '../BrownButtons/BrownButtonReg2';
import BrownButtonReg from '../BrownButtons/BrownButtonReg';
import { useSelector, useDispatch } from "react-redux";
import {inputloginname,inputpassword} from '../../storage/redux/slice/loginContainerSlice';


const LoginInput = ({onClick}) => {
    const inputvalue = useSelector((state) => state.loginContainerStore.login);
    const dispatch = useDispatch();
    //onChange={(e)=>dispatch(increment(e.target.value))}

    return (
        <div >
            <div className="loginrow">
                <BrownButtonReg2  title={"Email address"}/>&nbsp;&nbsp;
                <input type="email" id="loginemail" onChange={(e)=>dispatch(inputloginname(e.target.value))} pattern=".+@globex\.com" size="50" required/>
            </div>   
            <div className="loginrow">
                <BrownButtonReg2 title={"Password"}/>&nbsp;&nbsp;
                <input type="password" id="loginpassword" onChange={(e)=>dispatch(inputpassword(e.target.value))} size="50" required/>                    
            </div>
            <div className="loginrow">
                <BrownButtonReg   title={"Log In"} link={"/login"} onClick={onClick}/>  
            </div> 
        </div> 
    );
}
    
export default LoginInput;