import { createSlice } from "@reduxjs/toolkit";

const initialState = { login: '' };

export const loginSlice = createSlice({
    name: "login",
    initialState:initialState,
    reducers:{
        increment: (state,action) => {
            state.login = action.payload;
        },

    },
});

export const {increment} =  loginSlice.actions;
export const loginReducer = loginSlice.reducer;