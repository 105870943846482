import './WhatDoWeDo.css'
import RedCrossClose from './redcrossclose.png';
import BrownButtonReg2Pointer from '../BrownButtons/BrownButtonReg2Pointer';

import { Link } from "react-router-dom";




const WhatDoWeDo = ({WhatDoWeDo_Close,TermsLink}) => {
    return (
        <div className="msg_tmpl1_container0">
            <div className="msg_tmpl1_container1">
            <span className="msg_tmpl1_header_span">What we do:</span>
{/*            
            <img onClick={WhatDoWeDo_Close} alt="" style={{margin:'5px'}} src={RedCrossClose}/>
*/}            
            </div>

            <div className="msg_tmpl1_container2">
                <p>We help people and organizations that create or collect art, helping them find if their art is being used somewhere else... <br/><br/>
                What type of Art? &nbsp;&nbsp;&nbsp; Anything from canvas, digital (including NFT), sculpture, photography… <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any Image you consider “art”.*</p>
                
            </div>
            <div className="msg_tmpl1_container4">
            <BrownButtonReg2Pointer title={"Continue"} onClick={WhatDoWeDo_Close} />
               
{/*
               <span className="msg_tmpl1_footer_span" style={{color: '#AAA'}}> Don’t show this again: <input type="checkbox" id="dontshowagain"  name="dontshowagain" value=""/></span>
*/}
            </div>
        <div className="msg_tmpl1_container5">
            <span className="msg_tmpl1_footer_span">* Subject to our  <span className="termslink" onClick={TermsLink}> Terms and Conditions</span></span>
            </div> 
       </div> 
    );
}
    
export default WhatDoWeDo;