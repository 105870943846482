import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import './About.css';
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
//**scroll**
import { useScrollbar } from '../../Hooks/use-scrollbar';
import { useRef } from 'react';
//**********/

const About = () => {

//**scroll**
const isubscriptionWrapper = useRef(null);
const hasScroll =  true;
useScrollbar(isubscriptionWrapper, hasScroll);
//**********/

return (
  <div style={{ paddingRight: '35px',height: '425px',minHeight: '425px'}} ref={isubscriptionWrapper}>
about

  </div>
)
}
    
export default About;