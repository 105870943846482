import './PasswordReset.css'
import BrownButtonReg2 from '../BrownButtons/BrownButtonReg2'
import BrownButtonReg from '../BrownButtons/BrownButtonReg'


const PasswordResetReq = () => {

    return (
        <div>
            <div className="passresetrow">
                <BrownButtonReg2  buttontitle={"Email address"}/>&nbsp;&nbsp;
                <input type="email" id="emailpassreset" pattern=".+@globex\.com" size="50" />
            </div>   
            <div className="passresetrow">
                <BrownButtonReg2 buttontitle={"Phone Number"}/>&nbsp;&nbsp;
                <input type="tel"  size="50" id="phonepassreset" name="phonepassreset" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />                 
            </div>
            <div className="loginrow">
                <BrownButtonReg  buttontitle={"Reset Password"}/>    
            </div> 
        </div> 
    );
}
    
export default PasswordResetReq;