import './BrownButtons.css'

const BrownButtonReg2 = ({title,onClick}) => {

    return (

            <div className="brownbuttonreg2" onClick={onClick}>
                <div className="titlepadding">{title}</div>
            </div>

    );
}

export default BrownButtonReg2;  