import './Subscription.css'
import { useScrollbar } from '../../Hooks/use-scrollbar';
import { useRef } from 'react';
import CountryDropdown from 'country-dropdown-with-flags-for-react';

const Isubscription = () => {
    const isubscriptionWrapper = useRef(null);
    const hasScroll =  true;

    useScrollbar(isubscriptionWrapper, hasScroll);

    return (
        <div style={{ paddingRight: '35px',height: '425px',minHeight: '425px'}} ref={isubscriptionWrapper}>
            <div className="myaccount-content-row">

            <div className="subscription-ind myaccount-dashedborder">
                <div className="myaccount-setting-text">
                    <br/>Individual Account<br/><br/>
                </div>
                <div className="subscription-ind-content">
                    <div className="subscription-ind-content-left">
                        <form id="ui-table-form" action="/">
                            <table className="subscr-table-i-2">
                            <tbody>
                                <tr>
                                <td className="td-sb-i" >I would like my portfolio on DejaVu.art to allow searching for&nbsp;
                                <input type="number"  size="10" className="inputnumber1" id="tentacles" name="tentacles" min="1" max="9999"/> images</td>
                                </tr>
                                <tr>
                                <td className="td-sb-i" >I would like to cage (or “swap”) images in my portfolio <input type="checkbox" id="a2fa"  name="a2fa" value=""/></td>
                                </tr>
                            </tbody>
                            </table>
                            <br/><br/>Search details <br/><br/>
                            <table className="subscr-table-i-1">
                            <thead>
                                <tr>
                                <th className="th-border-bottom" colSpan="3">Notification</th>
                                <th className="th-border-bottom" colSpan="5">Frequency</th>
                                <th className="th-border-bottom" colSpan="3">Sec</th>
                                </tr>
                                <tr>
                                <th className="th-ui-reg th-border-bottom">Scr</th>
                                <th className="th-ui-reg th-border-bottom">SMS</th>
                                <th className="th-ui-reg th-border-bottom">EMail</th>
                                <th className="th-ui-reg th-border-bottom">By dem.</th>
                                <th className="th-ui-reg th-border-bottom">3/Day</th>
                                <th className="th-ui-reg th-border-bottom">Daily</th>
                                <th className="th-ui-reg th-border-bottom">Weekly</th>
                                <th className="th-ui-reg th-border-bottom">Monthly</th>
                                <th className="th-ui-reg th-border-bottom">Enable</th>
                                <th className="th-ui-reg th-border-bottom">2FA</th>
                                <th className="th-ui-reg th-border-bottom">Invite</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" defaultChecked id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" defaultChecked id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" defaultChecked id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border">n/a</td>
                                <td className="td-ui lasttd-ui-border"><input type="checkbox" defaultChecked id="uit_nt1" name="uit_nt1" value="scr"/></td>
                                <td className="td-ui lasttd-ui-border">n/a</td>
                                </tr>
                            </tbody>
                            </table>
                            <br/><br/>My details<br/><br/>
                            <table className="subscr-table-i-2">
                            <tbody>
                                <tr>
                                <td className="td-sb-i">First Name:</td>
                                <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="sb-fn" required/></td>
                                <td className="td-sb-i">Last Name:</td>
                                <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="sb-ln" required/></td>
                                </tr>
                                <tr>
                                <td className="td-sb-i">Email:</td>
                                <td className="td-sb-i"><input  className="subscr-input" type="email" id="email" pattern=".+@globex\.com" size="40" required/></td>
                                <td className="td-sb-i"></td>
                                <td className="td-sb-i"></td>
                                </tr>
                                <tr>
                                <td className="td-sb-i">Password:</td>
                                <td className="td-sb-i"><input className="subscr-input" size="25" type="password" id="sb-psw1" required/></td>
                                <td className="td-sb-i">Confirm password:</td>
                                <td className="td-sb-i"><input className="subscr-input" size="25" type="password" id="sb-psw2" required/></td>
                                </tr>
                                <tr>
                                <td className="td-sb-i">Phone number:</td>
                                <td className="td-sb-i"><input type="tel" className="subscr-input" id="phone" name="phone" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"  required/></td>
                                <td className="td-sb-i">2FA:</td>
                                <td className="td-sb-i"><input type="checkbox" id="a2fa" name="a2fa" value=""/></td>
                                </tr>

                            </tbody>
                            </table>

                            <br/><br/>
                            <table className="subscr-table-i-2">
                            <tbody>
                                <tr>
                                <td className="td-sb-i"></td>
                                <td className="td-sb-i"></td>
                                <td className="td-sb-i">Promo Code</td>
                                <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="sb-ln" required/></td>
                                </tr>
                                <tr>
                                <td className="td-sb-i"></td>
                                <td className="td-sb-i"></td>
                                <td className="td-sb-i">Calculated Price:</td>
                                <td className="td-sb-i">$35.99</td>
                                </tr>
                            </tbody>
                            </table>
                        </form>
                    </div>
                    <div className="subscription-ind-content-right">
                            <span className="subtitles">Billing Info</span>
                        <table className="subscr-table-i-2">
                        <tbody>
                            <tr>
                            <td className="td-sb-i">Card Number:</td>
                            <td className="td-sb-i">

                                <input className="inputCard" pattern="[0-9]{4}"  type="text" name="creditCard1" id="creditCard1"/>-<input className="inputCard"  maxLength="4" type="text" name="creditCard2" id="creditCard2"/>-<input className="inputCard"  maxLength="4" type="text" name="creditCard3" id="creditCard3"/>-<input className="inputCard"  maxLength="4" type="text" name="creditCard4" id="creditCard4"/>
                            </td>
                            <td className="td-sb-i">Address:</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="sb-ln" /></td>
                            </tr>
                            <tr>
                            <td className="td-sb-i">Expiry Date:</td>
                            <td className="td-sb-i">

                                <select name='expireMM' id='expireMM'>
                                    <option value=''>Month</option>
                                    <option value='01'>January</option>
                                    <option value='02'>February</option>
                                    <option value='03'>March</option>
                                    <option value='04'>April</option>
                                    <option value='05'>May</option>
                                    <option value='06'>June</option>
                                    <option value='07'>July</option>
                                    <option value='08'>August</option>
                                    <option value='09'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                </select>
                                <select name='expireYY' id='expireYY'>
                                    <option value=''>Year</option>
                                    <option value='20'>2020</option>
                                    <option value='21'>2021</option>
                                    <option value='22'>2022</option>
                                    <option value='23'>2023</option>
                                    <option value='24'>2024</option>
                                </select>
                                <input className="inputCard" type="hidden" name="expiry" id="expiry" maxLength="4"/>


                            </td>
                            <td className="td-sb-i">City</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="" required /></td>
                            </tr>
                            <tr>
                            <td className="td-sb-i">First Name:</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="sb-psw1" required/></td>
                            <td className="td-sb-i">State</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="" /></td>
                            </tr>
                            <tr>
                            <td className="td-sb-i">Last Name</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="" required /></td>
                            <td className="td-sb-i">Country</td>
                            <td className="td-sb-i"><CountryDropdown  id="UNIQUE_ID" className="subscr-input-country" preferredCountries={['us','ca']}  value="" handleChange={e => console.log(e.target.value)}></CountryDropdown></td>
                            </tr>
                            <tr>
                            <td className="td-sb-i"></td>
                            <td className="td-sb-i"></td>
                            <td className="td-sb-i">Zip/Postal code</td>
                            <td className="td-sb-i"><input className="subscr-input" size="25" type="text" id="" required /></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <input className="create-acc-button" type="submit" value="Create Account"/>
            </div>
            </div>
        </div>
  )

}

export default Isubscription;