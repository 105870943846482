import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
};

export const welcomeContainerSlice = createSlice({
    name: "welcomeContainer",
    initialState:initialState,
    reducers:{
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },

    },
});

export const {updateaction} =  welcomeContainerSlice.actions;
export const welcomeContainerReducer = welcomeContainerSlice.reducer;