import { useSelector, useDispatch } from "react-redux";
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import CreateTrial from '../../components/CreateTrial/CreateTrial';
//import './SubscribeContainer.css';
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const TrialReady = () => {
  const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
  const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
  //const containerurl = 'trialready';
  const dispatch = useDispatch();
  const navigate = useNavigate();
console.log('trialready');

  useEffect(() => {
    navigate(containerurl);
  }, [containerurl]);

  const TrialReady_Login = () => {
    dispatch(updatecontaineraction(''));
    dispatch(updatecontainerurl('/login'));        
  }  

messages['TrialReady'].buttonslistprops[0].onclick = TrialReady_Login;          
    return (
       <Msg1 buttonsprops={messages['TrialReady']}/> 
    );
}
    
export default TrialReady;