import { useSelector, useDispatch } from "react-redux";
import { updatecontainerurl,updatecontaineraction,setlogout,setlogin,setmediadata } from '../../storage/redux/slice/globalContainerSlice';
//import { inputloginname,inputpassword} from '../../storage/redux/slice/loginContainerSlice';
import './LoginContainer.css';
import { useNavigate } from "react-router-dom";
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useEffect } from "react";
import axios from "axios";

const LoginContainer = () => {
  const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
  const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
  const mediadata = useSelector((state) => state.globalContainerStore.mediadata);   
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //if(containerurl !== '/login') {navigate(containerurl);}
    useEffect(() => {
      navigate(containerurl);
    }, [containerurl]);

//<Button onClick={() => navigate('../login', { replace: true })}

    const LogInButton = () => {
        // Execute Login
        dispatch(setlogin()) 
        
        const getMedia = async () => {
          //const response = await axios.get(`https://localhost:7251/GetImages?pagenumber=0&perpage=1` );
          const response = await axios.get(`https://api.dejavu.art/GetImages?pagenumber=0&perpage=2` );
          dispatch(setmediadata(response.data));
        };
        getMedia();

        dispatch(updatecontaineraction(''));
        dispatch(updatecontainerurl('/media'));   
    }

    //LogInButton();

    const TryAgain_Button = () => {
      dispatch(updatecontaineraction(''));
      dispatch(updatecontainerurl('/login'));
      //navigate('/login');        
    }  
    const ForgotPassword_Button = () => {
      dispatch(updatecontaineraction('EmailForResetPassword'));
    }    

    const SubmitNewPassword_Button = () => {
      dispatch(updatecontaineraction('???'));
    }   

    const EmailResetPassword_Button = () => {
      dispatch(updatecontaineraction('WeSentPasswordResetEmail'));
    }   


    switch(containeraction) {
      case 'EmailForResetPassword':
            messages['EmailForResetPassword'].buttonslistprops[0].onclick = EmailResetPassword_Button;                      
            return (
              <Msg1 buttonsprops={messages[containeraction]}/> 
            );
            case 'EnterNewPassword':
              messages['EnterNewPassword'].buttonslistprops[0].onclick = SubmitNewPassword_Button;              
              return (
                <Msg1 buttonsprops={messages[containeraction]}/> 
              );       
      case 'WrongCredentials':
            messages['WrongCredentials'].buttonslistprops[0].onclick = TryAgain_Button;
            messages['WrongCredentials'].buttonslistprops[1].onclick = ForgotPassword_Button;
            return (
              <Msg1 buttonsprops={messages[containeraction]}/> 
            );
      case 'NewPasswordAccepted':
           messages['NewPasswordAccepted'].buttonslistprops[0].onclick = TryAgain_Button;        
           return (
               <Msg1 buttonsprops={messages[containeraction]}/> 
            );       
      case 'WeSentPasswordResetEmail':
            messages['WeSentPasswordResetEmail'].buttonslistprops[0].onclick = TryAgain_Button;
            return (
              <Msg1 buttonsprops={messages[containeraction]}/> 
            );
          
      default:  
            messages['LoginForm'].buttonslistprops[0].onclick = LogInButton;
            return (
              <Msg1 buttonsprops={messages['LoginForm']}/> 
            );
    }
}
    
export default LoginContainer;