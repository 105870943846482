import './PasswordReset.css'
import BrownButtonReg2 from '../BrownButtons/BrownButtonReg2'
import BrownButtonReg from '../BrownButtons/BrownButtonReg'


const PasswordReset = () => {

    return (
        <div>
            <div className="passresetrow">
                <BrownButtonReg2  buttontitle={"New Password"}/>&nbsp;&nbsp;
                <input type="password" id="resetpassword"  size="50" required/>  
            </div>   
            <div className="passresetrow">
                <BrownButtonReg2 buttontitle={"Confirm Password"}/>&nbsp;&nbsp;
                <input type="password" id="confirmresetpassword"  size="50" required/>                    
            </div>
            <div className="loginrow">
                <BrownButtonReg  buttontitle={"Submit"}/>    
            </div> 
        </div> 
    );
}
    
export default PasswordReset;