import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import './ExploreContainer.css'
import { useNavigate } from "react-router-dom";
import messages from  '../../components/messages';
import Msg1 from '../../components/Msg1/Msg1';
import { useEffect } from "react";

const ExploreContainer = () => {
    const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
    const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      navigate(containerurl);
    }, [containerurl]);

    const BackToExploreLoginCreate = () => {
        dispatch(updatecontaineraction('ExploreLoginCreate'));
        dispatch(updatecontainerurl('/welcome'));        
    }

    switch(containeraction) {
        case 'xxx':
              return (
                <Subscription/>
              );
        default:
          messages['OptionINotAvailable'].buttonslistprops[0].onclick =BackToExploreLoginCreate;          
          return (
            <Msg1 buttonsprops={messages['OptionINotAvailable']}/> 
              );
      }
}
    
export default ExploreContainer;

