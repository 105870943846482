import BrownButtonReg from '../BrownButtons/BrownButtonReg'
import BrownButtonFat from '../BrownButtons/BrownButtonFat'
import BrownButtonSmall from '../BrownButtons/BrownButtonSmall'
import PasswordReset from '../PasswordReset/PasswordReset'
import PasswordResetReq from '../PasswordReset/PasswordResetReq'
import LoginInput from '../LoginInput/LoginInput'

const BrownButtonList = ({buttonsprops}) => {

    switch(buttonsprops.brownbuttontype){
        case "BrownButtonFat":
                return (buttonsprops.buttonslistprops.map((buttonprops) => <BrownButtonFat key={buttonprops.title} title={buttonprops.title} link={buttonprops.link} onClick={buttonprops.onclick} />)); 
        case "BrownButtonSmall":
                return (buttonsprops.buttonslistprops.map((buttonprops) => <BrownButtonSmall key={buttonprops.title} title={buttonprops.title} link={buttonprops.link} onClick={buttonprops.onclick} />)); 
        case "LoginInput":
                console.log(2);
                return (<LoginInput onClick={buttonsprops.buttonslistprops[0].onclick}/>);                
        case "PasswordResetReq":
                 return (<PasswordResetReq />);  
        case "PasswordReset":
                  return (<PasswordReset />);                                         
        default:
            console.log("BrownButtonList switch defailt");
            return (buttonsprops.buttonslistprops.map((buttonprops) => <BrownButtonReg key={buttonprops.title} title={buttonprops.title} link={buttonprops.link} onClick={buttonprops.onclick} />));          
    }
}
export default BrownButtonList;