import './Subscription.css'
import SliderNobeImage from './Slider.svg'
import Shareemailsave from '../Shareemailsave/Shareemailsave'
import Bsubscription from './Bsubscription'
import Isubscription from './Isubscription'
import Pickaccounttype from '../Pickaccounttype/Pickaccounttype'

import { useState } from "react";
import backgroundlight from "../Pickaccounttype/pickaccountlight.svg";
import backgrounddark from "../Pickaccounttype/pickaccountdark.svg";

const Subscription = ({title}) => {

   const [isbsns, setAccType] = useState(false);
    
   const clickI = () => {
       document.getElementById('b-acc').style = 'color: #a5a5a4;cursor: pointer;';  
       document.getElementById('b-acc').style.backgroundImage=`url(${backgrounddark})`;
       document.getElementById('i-acc').style = 'color: white;cursor: default;';  
       document.getElementById('i-acc').style.backgroundImage=`url(${backgroundlight})`;  
       setAccType(false);        
    };

   const clickB = () => {
       document.getElementById('i-acc').style = 'color: #a5a5a4;cursor: pointer;';  
       document.getElementById('i-acc').style.backgroundImage=`url(${backgrounddark})`;
       document.getElementById('b-acc').style = 'color: white;cursor: default;';  
       document.getElementById('b-acc').style.backgroundImage=`url(${backgroundlight})`;    
       setAccType(true);               
       };

    return (
        <div className="frame1upcontainer">
        <div className="frame1header">
           <span className="frame1title">{title}</span>
           <div className="pickaccountswitch">
            <div id="i-acc" className="pickaccountswitchleft" onClick={clickI}>I'am  an individual</div>
            <div id="b-acc" className="pickaccountswitchright"  onClick={clickB}>I represent a business</div>
        </div>
           <div>&nbsp;</div>
         </div>

        <div className="frame1downcontainer">
        {isbsns ? <Bsubscription/> : <Isubscription/>}
          
        </div>
     </div> 
    )
}

export default Subscription; 