import { useEffect } from "react";
import { OverlayScrollbars } from 'overlayscrollbars';
import './use-scrollbar.css';

const config = {
    className       : 'os-theme-dark',
    overflow: {
        y: 'hidden',
      },
      scrollbars: {
        theme: 'os-theme-dark',
      },         
   
}

const useScrollbar = (root,hasScroll) => {
    useEffect(() => {
        let scrollbars;
        if(root.current && hasScroll){
            OverlayScrollbars(root.current, config);
        }

        return () => {
            if (scrollbars) {
                scrollbars.destroy();
            }
        }
    },[root , hasScroll]);
};

export {useScrollbar};