import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import './AccountContainer.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
//**scroll**
import { useScrollbar } from '../../Hooks/use-scrollbar';
import { useRef } from 'react';
import PortfolioImage from './img/Portfolio.svg';
import StatusBackgroundMediumImage from './img/StatusBackgroundMedium.svg';
import FrequencyImage from './img/Frequency.svg';
import StatusBackgroundLargeImage from './img/StatusBackgroundLarge.svg';
import AlertemailImage from './img/Alertemail.svg';
import AlertSMSImage from './img/AlertSMS.svg';
import AlertWebImage from './img/AlertWeb.svg';

//**********/

const AccountContainer = () => {
    const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
    const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
    const dispatch = useDispatch();
    const navigate = useNavigate();

//**scroll**
    const isubscriptionWrapper = useRef(null);
    const hasScroll =  true;
    useScrollbar(isubscriptionWrapper, hasScroll);
//**********/
    useEffect(() => {
      navigate(containerurl);
    }, [containerurl]);


    return (
  
      <div className="trashcontainer0">
        <div className="trashcontainer1">
          <span className="mytrashspan">My Account (just page sample)</span>
        </div>
        <div style={{ paddingRight: '35px',height: '425px',minHeight: '425px'}} ref={isubscriptionWrapper}>
          <div className="trashcontainer2" >


            <div className="myaccount-content-row">
                <div className="myaccount-settings myaccount-dashedborder">
                  <div className="myaccount-setting-text">
                    Settings
                  </div>
                  <div id="update-nbr" className="myaccount-twoimagesdiv myaccount-marginbottom">
                    <a href="/account" ><img className="underimage" src={StatusBackgroundMediumImage}/>
                        <img className="portfolioimage" src={PortfolioImage}/>
                        <span className="portfolioimagetext">4 / 50</span>
                    </a>
                    <div className="myaccount-setting-update myaccount-setting-update-nbr">
                         Current Number of media is 20. You can change it any time.
                        <form style={{padding: '20px 0', display: 'flex',flexDirection:'column',justifyContent: 'center'}}>
                          <input name="n"
                                 type="number"
                                 min="1"
                                 max="1000"
                                 step="2"
                                 value="20"/><br/>
                          <input type="submit" value="Change"/>
                        </form>
                    </div>
                  </div>

                <div id="update-fr" className="myaccount-twoimagesdiv myaccount-marginbottom">
                    <a href="/account" ><img className="underimage" src={StatusBackgroundMediumImage}/>
                      <img className="frequencyimage" src={FrequencyImage}/>
                      <span className="frequencyimagetext">Daily</span>
                    </a>
                <div className="myaccount-setting-update  myaccount-setting-update-fr">
                      Frequency
                      <form id="fr-update-form" action="/"><br/><br/>
                          <input type="radio" id="fr1" name="fr" value="1"/> <label for="fr1">Monthly</label><br/>
                          <input type="radio" id="fr2" name="fr" value="2"/> <label for="fr2">Weekly</label><br/>
                          <input type="radio" id="fr3" name="fr" checked value="3"/><label for="fr3">Daily</label><br/>
                          <input type="radio" id="fr4" name="fr" value="4"/><label for="fr4">2 time a day</label><br/>
                          <input type="radio" id="fr5" name="fr" value="5"/><label for="fr5">By Request</label><br/><br/>
                          <input type="submit" id="submit-update-fr" value="Submit"/>
                       </form>
                </div>
              </div>

                <div id="update-nt" className="myaccount-twoimagesdiv setting-update-form">
                    <a href="/account/" ><img className="underimage" src={StatusBackgroundLargeImage}/>
                    <img className="alertemailimage" src={AlertemailImage}/>
                    <img className="alertsmsimage" src={AlertSMSImage}/>
                    <img className="alertwebimage" src={AlertWebImage}/></a>
                    <div className="myaccount-setting-update myaccount-setting-update-nt">
                        Notification

                        <form id="nt-update-form" action="/"><br/><br/>
                          <input type="checkbox" id="scr_nt" checked name="scr_nt" value="1"/><label for="scr_nt"> Screen </label><br/>
                          <input type="checkbox" id="email_nt" checked name="email_nt" value="2"/><label for="email_nt"> Email </label><br/>
                          <input type="checkbox" id="sms_nt" name="sms_nt" value="3"/><label for="sms_nt"> SMS </label><br/><br/>
                          <input type="submit" id="submit-update-nt" value="Submit"/>
                        </form> 
                    </div>
                </div>
          </div>
          <div className="myaccount-myinfo myaccount-dashedborder">
                <div className="myaccount-setting-text">
                    My Information
                    <span style={{color: '#9f0016',fontWeight: '600', fontSize: '18px'}}>(<a href="/account" style={{color: '#9f0016'}}>edit</a>)</span>
                </div>
                <p>Tom Winter</p>
                <p>tomwinter@aaaa.com</p>
                                  <p>1-416-1231212</p>
                <p>1234 Park av. suite 203<br/>USA Seatle WA 12345</p>
                <p>Account started: 02/23/22</p>
                <p>Account status: <span style={{color: 'green', fontWeight: 700}}>active </span></p>
                <p>2FA: <span style={{color: 'green', fontWeight: 700}}>enabled </span></p>
                <p>Password: ********** <span>(<a href="/account" style={{color: '#9f0016',fontWeight: '600'}}>change</a>)</span></p>
          </div>
          <div className="myaccount-billinginfo myaccount-dashedborder">
                <div className="myaccount-setting-text">
                    Billing Information
                    <span style={{color: '#9f0016',fontWeight: '600', fontSize: '18px'}}>(<a href="/account" style={{color: '#9f0016'}}>edit</a>)</span>
                </div>
                <div className="myaccount-billinginfo-content">
                    <div className="myaccount-billinginfo-left">
                        <p><b>Credit Card Number:</b></p> <p>**** **** **** 1234</p> 
                        <p><b>Expiry Date:</b></p>
                        <p>11/25</p>
                    </div>
                    <div className="myaccount-billinginfo-right">
                        <p><b>Last Name:</b></p>
                        <p>Winter</p>
                        <p><b>First Name:</b></p>
                        <p>Tom</p>
                        <p><b>Billing Address:</b></p>
                        <p>1234 Park av. suite 203<br/>USA<br/>Seatle WA<br/> 12345</p>
                    </div>
                </div>
          </div>
      </div>

      <div class="myaccount-content-row">
          <div class="myaccount-billinghistory myaccount-dashedborder">
                <div class="myaccount-setting-text">
                    Billing History
                </div>

                    <table class="mybhtable">
                    <thead>
                      <tr>
                        <th class="tg-bh">Description</th>
                        <th class="tg-bh">Date</th>
                        <th class="tg-bh">Amount</th>
                        <th class="tg-bh"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="tg-bh td1-bh">Invoice #111111</td>
                        <td class="tg-bh td2-bh">2023-01-01</td>
                        <td class="tg-bh td3-bh">$29.99</td>
                        <td class="tg-bh td4-bh"><a  href="/account"  >Download PDF</a></td>
                      </tr>
                      <tr>
                        <td class="tg-bh td1-bh">Payment #222222</td>
                        <td class="tg-bh td2-bh">2023-01-01</td>
                        <td class="tg-bh td3-bh">$39.99</td>
                        <td class="tg-bh td4-bh"><a  href="/account"  >Download PDF</a></td>
                      </tr>
                    </tbody>
                    </table>


          </div>
          <div class="myaccount-accounthistory myaccount-dashedborder">
                <div class="myaccount-setting-text">
                    Account History
                </div>
                    <table class="myahtable">
                    <thead>
                      <tr>
                        <th class="tg-ah">Description</th>
                        <th class="tg-ah">Date</th>
                        <th class="tg-ah">Amount</th>
                        <th class="tg-ah">Balance </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="tg-ah td1-ah">200 searchs added </td>
                        <td class="tg-ah td2-ah">2023-01-01</td>
                          <td class="tg-ah td3-ah"><span class="span-ah">-$29.99</span></td>
                        <td class="tg-ah td4-ah"><span class="span-ah">-$39.99</span></td>
                      </tr>
                      <tr>
                        <td class="tg-ah td1-ah">Payment #222222</td>
                        <td class="tg-ah td2-ah">2023-01-01</td>
                        <td class="tg-ah td3-ah">$39.99</td>
                        <td class="tg-ah td4-ah">$00.00</td>
                      </tr>
                    </tbody>
                    </table>

          </div>
          <div class="myaccount-exurl myaccount-dashedborder">
                <div class="myaccount-setting-text">
                    URL Exception list<br/>
                    (coming soon)
                </div>

          </div>
      </div>

    </div>
           </div>
        </div>

    
    
    )

}
    
export default AccountContainer;

