import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
    containerurl: '/welcome' ,
    logged: 0,
    mediadata: [],
    searchresults: []
};

export const globalContainerSlice = createSlice({
    name: "globalContainer",
    initialState:initialState,
    reducers:{
        updatecontainerurl: (state,action) => {
            state.containerurl = action.payload;
        },  
        updatecontaineraction: (state,action) => {
            state.containeraction = action.payload;
        },
        setmediadata: (state,action) => {
            state.mediadata = action.payload;
        },        
        setsearchresults: (state,action) => {
            console.log("action.payload");
            console.log(action.payload);
            state.searchresults = [...action.payload, ...state.searchresults ] ;            
        },         
        addmediadata: (state,action) => {
            state.mediadata = [...action.payload, ...state.mediadata ] ;
        },         
        setlogout: (state) => {
            state.logged = 0;
        },
        setlogin: (state) => {
            state.logged = 1;
        }, 

    },
});

export const {setlogout,
              setlogin,
              updatecontainerurl,
              updatecontaineraction,
              setmediadata,
              addmediadata,
              setsearchresults
             } =  globalContainerSlice.actions;
export const globalContainerReducer = globalContainerSlice.reducer; 
