import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import 'overlayscrollbars/overlayscrollbars.css';
import { BrowserRouter } from 'react-router-dom';
//import   store   from "./storage/redux/store"; 
import  { store }  from "./storage"; 
import { Provider } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>

      <App />
    </BrowserRouter>
  </Provider>
);

/*
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>

);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();