import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
};

export const payContainerSlice = createSlice({
    name: "payContainer",
    initialState:initialState,
    reducers:{
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },

    },
});

export const {updateaction} =  payContainerSlice.actions;
export const payContainerReducer = payContainerSlice.reducer;