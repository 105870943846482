import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import './MediaContainer.css';
import Msg1 from '../../components/Msg1/Msg1';
import Loader from '../../components/Loader/Loader';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import PageLeftImage from '../../assets/img/misc/PageLeft.png';
import PageRightImage from '../../assets/img/misc/PageRight.png';
import SortMenuNoLineImage from '../../assets/img/misc/SortMenuNoLine.svg';
import SliderImage from '../../assets/img/misc/Slider.svg';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from "react";

import PortfolioSelectBackgroundImage from '../../assets/img/misc/PortfolioSelectBackground.svg';
import PortfolioSizeLargeImage from '../../assets/img/misc/PortfolioSizeLarge.svg';
import PortfolioSizeSmallImage from '../../assets/img/misc/PortfolioSizeSmall.svg';

import { useScrollbar } from '../../Hooks/use-scrollbar-x';
import { updatecontainerurl,updatecontaineraction,setmediadata,setlogin,addmediadata,setsearchresults } from '../../storage/redux/slice/globalContainerSlice';
import {BytesTo64} from '../../functions';

const LargeMedia = ({data,dbclick,id}) => {
  //const data64 = BytesTo64(data);
  return (
    <img className="largemediaimage" id={id} title="Double Click for Search" onClick={dbclick} src={`data:image/jpeg;base64,${data}`} /> 
  );
}

const SmallMedia = ({data,dbclick,id}) => {
  //const data64 = BytesTo64(data);
  return (
    <div className="smallmediadiv">
      <img className="smallmediaimage" id={id} title="Double Click for Search"  onClick={dbclick}  src={`data:image/jpeg;base64,${data}`} /> 
    </div>
  );
}

const SmallMediaList = ({mediadata,dbclick}) => {
   
    return (
      <div className='smallmedialist'>
          {mediadata.map((imagedata) => {
              return (
                  <SmallMedia
                      key={imagedata.id}
                      data={imagedata.data}
                      id={imagedata.id}
                      dbclick={dbclick}
                  />
              );
          })}
      </div>
  );


}

const LargeMediaList = ({mediadata,dbclick}) => {
  /*const [largeMediaData, setLargeMediaData] = useState([]);
  const [loading, setLoading] = useState(false);

  /useEffect(async () => {
        const response = await axios.get(
            `https://api.dejavu.art/GetImages?pagenumber=2&perpage=20`
        );


        
        //setLargeMediaData((prev) => { return [...prev, ...response.data]; });
        setLargeMediaData(response.data);        
        setLoading(false);
    }, []); 


    useEffect(() => {
      const getMedia = async () => {
        const response = await axios.get(`https://api.dejavu.art/GetImages?pagenumber=2&perpage=20` );
        //setLargeMediaData((prev) => { return [...prev, ...response.data]; });
        setLargeMediaData(response.data);        
        setLoading(false);
      };
      getMedia();
    }, []);
*/
    
    return (
      <div className='largemedialist'>
          {mediadata.map((imagedata) => {
              return (
                  <LargeMedia
                      key={imagedata.id}
                      data={imagedata.data}
                      id={imagedata.id}
                      dbclick={dbclick}
                  />
              );
          })}
      </div>
  );


}


const MediaContainer = () => {
    const [smallicons, setIconsSize] = useState(false);
    const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
    const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
    const mediadata = useSelector((state) => state.globalContainerStore.mediadata);    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const [MediaData, setMediaData] = useState([]);

    //setMediaData(mediadata);

    dispatch(setlogin()) 
/*
    useEffect(() => {
      const getMedia = async () => {
        setLoading(true);
        const response = await axios.get(`https://api.dejavu.art/GetImages?pagenumber=0&perpage=45` );
        //setLargeMediaData((prev) => { return [...prev, ...response.data]; });
        console.log('bf setmediadata');
        dispatch(setmediadata(response.data));
        console.log('aft setmediadata');
        //setMediaData(response.data); 
        setLoading(false);
      };
      getMedia();
    }, []);

*/
    //if(containerurl !== '/media') {navigate(containerurl);}

    useEffect(() => {
      navigate(containerurl);
    }, [containerurl]);


    const mediaWrapper = useRef(null);
    const hasScroll =  true;

    useScrollbar(mediaWrapper, hasScroll);

    const switchSmallLargeIcons = () => {
      if(smallicons){
        // small icons, swith to large
        document.getElementById('SmallIconsImage').style.display = 'none';  
        document.getElementById('LargeIconsImage').style.display = 'block';
      }else{
        // large icons, swith to small
        document.getElementById('SmallIconsImage').style.display = 'block';  
        document.getElementById('LargeIconsImage').style.display = 'none';
      }
      setIconsSize(!smallicons);               
    };

    const DoubleClickForSearch = (event) => {
      if (event.detail === 2) {
          console.log('double click');
        
        console.log(event.target.id);
        let  fileforsearch;
        
        for (let i in mediadata) {
          if(mediadata[i].id === event.target.id) {fileforsearch = mediadata[i]; break;}
        }
        console.log(fileforsearch);

        const sendSearchFile = async () => {
          //const response = await axios.post(`https://localhost:7251/FileForSearch`, fileforsearch)
          //const response = await axios.post(`https://apitest.dejavu.art/FileForSearch`, fileforsearch)
          const response = await axios.post(`https://api.dejavu.art/FileForSearch`, fileforsearch)          

          //.then(response => dispatch(addmediadata(response.data)) );
          .then(response => dispatch(setsearchresults(response.data)) );
          //addmediadata
          //dispatch(setmediadata(response.data));
        };
        sendSearchFile(); 

      }
      //dispatch(updatecontainerurl(''));         
  }


/*
  (async () => {
    // POST request using axios with async/await
    const element = document.querySelector('#post-request-async-await .article-id');
    const article = { title: 'Axios POST Request Example' };
    const response = await axios.post('https://reqres.in/api/articles', article);
    element.innerHTML = response.data.id;
})();  */



      /*
        <div className="leftarrow">
          <img  alt="" src={PageLeftImage} height="85" width="35"/>
        </div>DoubleClickForSearch
      */
 
  return (
    <>
      <div className="searchcontainer0">

            <div className="searchcontainer1">
            <img alt="" className="sortmenuimage" src={SortMenuNoLineImage}/>
            <div className="twoimagesdiv">
              <img alt="" onClick={switchSmallLargeIcons} className="portfoliobgimage" src={PortfolioSelectBackgroundImage}/>
              <img alt="" id="LargeIconsImage" className="portfoliolargeimage" src={PortfolioSizeLargeImage}/>
              <img alt="" id="SmallIconsImage" className="portfoliosmallimage" src={PortfolioSizeSmallImage}/>
            </div>
          </div>
        <div className="searchcontainer2" ref={mediaWrapper}>
            {smallicons ? 
            <div>
              <SmallMediaList mediadata={mediadata} dbclick={DoubleClickForSearch}/>
            </div>
            :
            <div>
              <LargeMediaList mediadata={mediadata} dbclick={DoubleClickForSearch}/>
            </div>
            }
            
        </div>

      </div>
  </>
  )

}
    
export default MediaContainer;

/* 

          <div className="searchcontainer3">
              <div className="scbarr">
                  <img alt="" id="portfolioslider" src={SliderImage}/>
              </div>
          </div>


<!--<a href=""><img className="portfoliosmallimage" src="./img/portfoliomenu/PortfolioSizeSmall.svg"></a> -->
    switch(containeraction) {
      case 'ExploreLoginCreate':
            return (
              <Msg1 buttonsprops={messages[containeraction]}/> 
            );
      default:
            return (
              <WhatDoWeDo/>
            );
    }


*/