import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import './PayContainer.css';
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const PayContainer = () => {
    const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
    const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      navigate(containerurl);
    }, [containerurl]);
    
    const Something_click = () => {
      dispatch(updatecontaineraction('xxx'));
      //dispatch(updatecontainerurl(''));        
   }

    switch(containeraction) {
      case 'ExploreLoginCreate':
            return (
              <Msg1 buttonsprops={messages[containeraction]}/> 
            );
      default:
            return (
              <WhatDoWeDo />
            );
    }
}
    
export default PayContainer;