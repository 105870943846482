import { useSelector, useDispatch } from "react-redux";
import './ReportsContainer.css';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useScrollbar } from '../../Hooks/use-scrollbar';
import { useRef } from 'react';
import ArrowdownImage from '../../assets/img/misc/arrowdown.png';
import Cross1Image from '../../assets/img/misc/cross1.png';
import GreenmarkImage from '../../assets/img/misc/greenmark.png';
import RedNumber23Image from '../../assets/img/misc/RedNumber23.png';
import Artsample2Image from '../../assets/img/misc/artsample2.png';
import Artsample3Image from '../../assets/img/misc/artsample3.png';




const ReportObject = ({id,data}) => {

const searchresults = useSelector((state) => state.globalContainerStore.searchresults);
const id1 = id;

for (let res of searchresults) {
  console.log("res.id---------------");
  console.log(res.id);
  console.log("searchimageid");
  console.log(res.searchImageId);
  console.log("---------------");  
  if(res.searchImageId == id1){
    return (
      <div className="myreports-content-item">
      <div className="myreports-content-item-left">
          <img className="myreports-content-item-image" src={`data:image/jpeg;base64,${data}`}/>
      </div>
      <div className="myreports-content-item-right">
      <div className="myreports-content-item-header">
        <div className="myreports-content-item-header-text">
        Summary:
        </div>
        <div className="myreportcontentmenu">
            <div className="myreportmenu-item">Share</div>
            <div className="myreportmenu-item">Email</div>
            <div className="myreportmenu-item">Save</div>
        </div>
      </div>
      <div className="myreports-content-item-body">
          <table className="myreportstable">
          <thead>
            <tr>
              <th className="tg-0lax">Found Images</th>
              <th className="tg-0lax">Related URL</th>
              <th className="tg-0lax">Confidence</th>
              <th className="tg-0lax">Found online</th>
              <th className="tg-0lax">Notification</th>
              <th className="tg-0lax">Acknowledged</th>
            </tr>
          </thead>
          <tbody>
    
          {searchresults.map((imagedata,id) => {
                       console.log("id=");
               console.log(id1);
                if(id1===imagedata.searchImageId){
                    return (
                        <FoundImageReportObject
                            imageurl={imagedata.imageurl}
                            pageurl={imagedata.pageurl} 
                            confidence={imagedata.confidence}
                            downloaddate={imagedata.downloaddate}                            
                            data={imagedata.thumbData}
                        />
                    );
                }
            })
            }
    
          </tbody>
          </table>
      </div>
      </div>
    </div>
    
    );
  }
}
return ("");




}

const FoundImageReportObject = ({pageurl,imageurl,confidence,downloaddate,data}) => {

  return(
      <tr>
        <td className="tg-0lax td1"><img className="myreports-content-item-image" src={`data:image/jpeg;base64,${data}`}/></td>
        <td className="tg-0lax td2">{pageurl}<br/>{imageurl}</td>
        <td className="tg-0lax td3">{confidence}%</td>
        <td className="tg-0lax td4">{downloaddate}</td>
        <td className="tg-0lax td5"></td>
        <td className="tg-0lax td6">
            
        </td>
      </tr>
  );

}

const ReportObjects = () => {
  const searchresults = useSelector((state) => state.globalContainerStore.searchresults);
  const mediadata = useSelector((state) => state.globalContainerStore.mediadata);
  
  return (
        <>
        {mediadata.map((imagedata) => {
            return (
                <ReportObject
                    id={imagedata.id}
                    data={imagedata.data}
                />
            );
        })
        }
        </>

);

}

const ReportsContainer = () => {
  const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
  const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(containerurl);
  }, [containerurl]);

//**scroll**
const isubscriptionWrapper1 = useRef(null);
const hasScroll =  true;
useScrollbar(isubscriptionWrapper1, hasScroll);
//****** border: 1px solid blue;  ****/

return (
  
  <div className="reportscontainer0">
  <div className="reportscontainer1">
          <span className="myreportsspan">My Reports</span>
        </div>
        <div style={{ paddingRight: '12px',height: '304px',minHeight: '304px'}} ref={isubscriptionWrapper1}>
        <div className="reportscontainer2" >


         <ReportObjects />


       </div>
    </div>
  </div>


)
} 
export default ReportsContainer;