import './BrownButtons.css'

const BrownButtonSmall = ({title,onClick}) => {
 
    return (
            <div className="brownbuttonsmall" onClick={onClick}>
                <div className="titlepadding">{title}</div>
            </div>

    );
}

export default BrownButtonSmall;  