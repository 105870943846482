import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
};

export const exploreContainerSlice = createSlice({
    name: "exploreContainer",
    initialState:initialState,
    reducers:{
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },

    },
});

export const {updateaction} =  exploreContainerSlice.actions;
export const exploreContainerReducer = exploreContainerSlice.reducer;