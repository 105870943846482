import './CreateTrial.css'
import React, { useState } from "react";
import BrownButtonReg2 from '../BrownButtons/BrownButtonReg2';
import BrownButtonReg from '../BrownButtons/BrownButtonReg';
import { useRegisterUserMutation } from "../../Apis/authApi";
import { inputHelper} from "../helper";
import { apiResponse } from "../../Interfaces";
import { useNavigate } from "react-router-dom";
import { updatecontainerurl } from '../../storage/redux/slice/globalContainerSlice';
import {  useDispatch } from "react-redux";
import  MainLoader  from "../MainLoader";


const CreateTrial = () => {

   const [registerUser] = useRegisterUserMutation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState({
    userName: "",
    password: "",
    password2: "",  
    role: "",
    name: "",
  });

  const handleUserInput = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const tempData = inputHelper(e, userInput);
      setUserInput(tempData);
      setError("");
    };

    function timeout(delay: number) {
      return new Promise( res => setTimeout(res, delay) );
  }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(userInput.userName))){
        setError("Error: You have entered an invalid email address!");
      }else if(userInput.password !== userInput.password2){       
        setError("Error: Password mismatch");
      }else  if(userInput.password === ""){       
        setError("Error: Password field is empty");
      } else {
          setLoading(true);
          const response: apiResponse = await registerUser({
            userName: userInput.userName,
            password: userInput.password,
            role: "admin",
            name: userInput.userName,
          });
          if (response.data) {
            dispatch(updatecontainerurl('/trialready')); 
            navigate("/trialready");
          } else if (response.error) {
            setError("Error: " + response.error.data.errorMessages[0]);
          }

          setLoading(false);
      }
    };
    
      return (
          <div className="frame1upcontainer">
            <div className="frame1header">
              <span className="frame1title">Registration for 30 days trial plan</span>
              <p className="errormessage">{errorMessage}&nbsp;&nbsp;</p>   
            </div>


            <div className="frame1downcontainer">
            {loading && <MainLoader />}
              <div className="loginrow1">
                  <BrownButtonReg2  title={"Email address"} onClick=""/>&nbsp;&nbsp;
                  <input type="email" name="userName" id="loginemail1" value={userInput.userName} onChange={handleUserInput} pattern=".+@globex\.com" size={50} required/>
              </div>   
              <div className="loginrow1">
                  <BrownButtonReg2 title={"Password"}  onClick=""/>&nbsp;&nbsp;
                  <input type="password" name="password" id="loginpassword1" value={userInput.password} onChange={handleUserInput} size={50} required/>                    
              </div>
              <div className="loginrow1">
                  <BrownButtonReg2 title={"Confirm Password"}  onClick=""/>&nbsp;&nbsp;
                  <input type="password" name="password2" id="loginpassword2" value={userInput.password2} onChange={handleUserInput} size={50} required/>                    
              </div>                
              <div className="loginrow1">
                  <BrownButtonReg   title={"Create an account"}  onClick={handleSubmit}  />  
              </div> 
            </div>
          </div> 
      )
}

export default CreateTrial; 