import './BrownButtons.css'

const BrownButtonFat = ({title,onClick}) => {
  
    return (
 
            <div className="brownbuttonfat" onClick={onClick}>
                <div className="titlepadding">{title}</div>
            </div>

    );
}

export default BrownButtonFat;
