import './Msg1.css'
import BrownButtonList from '../BrownButtonList/BrownButtonList'

const Msg1 = ({buttonsprops}) => {

    return (
        <div className="container">
            <div className="divtitle">
                <span className="spantitle">{buttonsprops.title}</span>
            <div>&nbsp;</div>
            </div>
            <div className="divtext">
                <p>{buttonsprops.text}</p>
            </div>
            <div className="buttons">
                <BrownButtonList buttonsprops={buttonsprops} />           
            </div>
        </div> 
    );
}
    
export default Msg1;