const emptyFunc = () => { return undefined; }


const  messages: any  = {
  '100':{
    buttonslistprops: [''],
    title: '',
    text: '',  
    brownbuttontype: 'BrownButtonReg',
  },

  // Msg1
  'AreYouSureStartOver':{
    buttonslistprops: [
      {title:'Resume', link:'/subscription', onclick:''},
      {title:'Restart', link:'/subscription', onclick:''},
      ],    
    title: 'Are You Sure?',
    text: 'Anything you did before will be lost.',  
    brownbuttontype: 'BrownButtonReg',
  },

  'PickUpStartOver':{
    buttonslistprops: [
      {title:'Pick Up', link:'/subscription', onclick:''},
      {title:'Start Over', link:'/subscription', onclick:''},
      ], 
    title: '',
    text: 'We see that you were in the process of setting up an account before. Do you want to pick up where you left off or start over?',  
    brownbuttontype: 'BrownButtonReg',
  },

  'GuideOrPick':{
    buttonslistprops: [
      {title:'Guide Me', link:'/subscription', onclick:''},
      {title:'Let Me Pick', link:'/subscription', onclick:''},
      ],    
    title: 'New Customer',
    text: 'Welcome! How would you like to select the type of account?',  
    brownbuttontype: 'BrownButtonReg',
  },

  'ExploreLoginCreate':{
    buttonslistprops: [
      {title:'Create a trial Account', link:'/explore', onclick:emptyFunc},
      {title:'Explore', link:'/subscription', onclick:emptyFunc},
      {title:'Log In', link:'/login', onclick:emptyFunc}
      ],
    title: 'What you would like to do?',
    text: 'We see that you are a new to DejaVu.art. Here is our offer - 30 day trial! Would you like to explore what we do, create an account, or would you like to try to log in to an existing account?',  
    brownbuttontype: 'BrownButtonReg',
  },

  'EmailPhoneNotFound.':{
    buttonslistprops: [
      {title:'Try Again', link:'/login', onclick:''},
      {title:'Create a new account', link:'/subscription', onclick:''},
      {title:'Reset Password', link:'/login', onclick:''}
      ],    
    title: 'Log In',
    text: 'Email or phone not found.',  
    brownbuttontype: 'BrownButtonReg',
  },

  'WeSentPasswordResetEmail':{
    buttonslistprops: [
      {title:'Continue', link:'/login', onclick:''},
    ],      
    title: 'Password reset',
    text: 'We sent you password reset link to your email.',  
    brownbuttontype: 'BrownButtonReg',
  },

  'NewPasswordAccepted':{
    buttonslistprops: [
      {title:'Continue', link:'/login', onclick:''},
    ],      
    title: 'Password reset',
    text: 'The new password accepted. Please click Continue to login',  
    brownbuttontype: 'BrownButtonReg',
  },

  'WrongCredentials':{
    buttonslistprops: [
      {title:'Try again', link:'/login', onclick:''},
      {title:'Forgot password', link:'/login', onclick:''},
    ],    
    title: 'Log In',
    text: 'Wrong password or username',  
    brownbuttontype: 'BrownButtonReg',
  },

  'EmailTaken':{
    buttonslistprops: [
      {title:'Use another email', link:'/subscription', onclick:''},
      {title:'Forgot password', link:'/login', onclick:''},
      {title:'Log In', link:'/login', onclick:''},
    ],    
    title: 'Sing Up',
    text: 'This email already taken. Please choose one of the following options:',  
    brownbuttontype: 'BrownButtonReg',
  },
  'AccountBanned':{
    buttonslistprops: [
      {title:'Contact support', link:'/contact', onclick:''},
    ],     
    title: 'Log In',
    text: 'Your account is banned , please contact our support',  
    brownbuttontype: 'BrownButtonReg',
  },

  'EmailVerified':{
    buttonslistprops: [
      {title:'Log In', link:'/login', onclick:''},
    ],    
    title: '',
    text: 'Your email address is verified. Thank you!',  
    brownbuttontype: 'BrownButtonReg',
  },

  'TrialReady':{
    buttonslistprops: [
      {title:'Continue', link:'/login', onclick:''},
    ],    
    title: 'Trial Plan',
    text: 'Congratulations! Your account with  30 days trial plan is ready. Please Log In.',  
    brownbuttontype: 'BrownButtonReg',
  },

  'SearchOnDemandOrUpgrade':{
    buttonslistprops: [
      {title:'Run the search right now for $1.99', link:'/media', onclick:''},
      {title:'Upgrade the plan: add swap', link:'/media', onclick:''},
      {title:'Upgrade the plan: increase the number of images for search.', link:'/media', onclick:''},
      {title:'Upgrade the plan: increase the number of images for search.', link:'/media', onclick:''}
    ],
    title: 'Search On Demand',
    text: 'Your plan doesn`t allow to run search right now. The system will start searching accourding to schedule. You can search right now for $1.99. Please choose on of the following options:',  
    brownbuttontype: 'BrownButtonFat',
  },

  'LoginForm':{
    buttonslistprops: [
      {title:'Log In', link:'/login', onclick:''}
      ],
    title: 'Welcome Back',
    text: 'Please enter your credentials:',  
    brownbuttontype: 'LoginInput',
  },

  'CreateTrial':{
    buttonslistprops: [
      {title:'Log In', link:'/login', onclick:''}
      ],
    title: '',
    text: '',  
    brownbuttontype: 'CreateTrial',
  },

  'EmailForResetPassword':{
    buttonslistprops: [
      {title:'Reset Password', link:'/login', onclick:''}
      ],
    title: 'Password Reset',
    text: "Please enter your email OR phone number then click 'Reset Password'. We will send you reset instruction to your email.",  
    brownbuttontype: 'PasswordResetReq',
  },

  'EnterNewPassword':{
    buttonslistprops: [
      {title:'Submit', link:'/login', onclick:''}
      ],
    title: 'Password Reset',
    text: "Please create a new password",  
    brownbuttontype: 'PasswordReset',
  },
  
  'OptionINotAvailable':{
    buttonslistprops: [
      {title:'Continue', link:'/login', onclick:''}
      ],
    title: '',
    text: "This option is not available yet",  
    brownbuttontype: 'BrownButtonReg',
  },

};
    export default messages; 
 