import { useSelector, useDispatch } from "react-redux";
import { updateaction } from '../../storage/redux/slice/welcomeContainerSlice';
import { updatecontainerurl,updatecontaineraction } from '../../storage/redux/slice/globalContainerSlice';
import WhatDoWeDo from '../../components/WhatDoWeDo/WhatDoWeDo';
import Subscription from '../../components/Subscription/Subscription';
import './TrashContainer.css'
import Msg1 from '../../components/Msg1/Msg1';
import messages from  '../../components/messages';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
//**scroll**
import { useScrollbar } from '../../Hooks/use-scrollbar';
import { useRef } from 'react';
import Frequency from './Frequency.svg';
//**********/

const TrashContainer = () => {
  const containeraction = useSelector((state) => state.globalContainerStore.containeraction);
  const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(containerurl);
  }, [containerurl]);
    
//**scroll**
const isubscriptionWrapper = useRef(null);
const hasScroll =  true;
useScrollbar(isubscriptionWrapper, hasScroll);
//**********/
//  <div style={{ paddingRight: '35px',height: '425px',minHeight: '425px'}} ref={isubscriptionWrapper}>
return (
  
      <div className="trashcontainer0">
      <div className="trashcontainer1">
              <span className="mytrashspan">My Trash</span>
              <div className="emptytrashdiv">Empty<br/>Trash</div>
            </div>
            <div style={{ paddingRight: '35px',height: '425px',minHeight: '425px'}} ref={isubscriptionWrapper}>
            <div className="trashcontainer2" >
            <div className="trashcontainer3">

<div className="trashmedia1"  style={{ marginLeft: '60px'}}>
    <div className="trashmedia2">
        <div className="trashmedia2-box">
            Delete<br/>Now
        </div>
        <div className="trashmedia2-box">
            Restore 
        </div>
    </div>
    <div className="trashmedia3">
        <div className="trashmedia3-box">
            <img className="thumbnailintrash"     src={Frequency}/><br/>
        </div>
        <div className="trashmedia3-box">
            Image Name
        </div>
        <div className="trashmedia3-box">
            Uploaded: 12/12/23
        </div>
        <div className="trashmedia3-box">
            Put in trash : 12/12/23
        </div>
    </div>
</div>
<div className="trashmedia1">

</div>
<div className="trashmedia1">

</div>
<div className="trashmedia1">

</div>
<div className="trashmedia1" style={{marginRight: '60px'}}>

</div>
</div>              
                <div className="trashcontainer3">

                    <div className="trashmedia1"  style={{ marginLeft: '60px'}}>
                        <div className="trashmedia2">
                            <div className="trashmedia2-box">
                                Delete<br/>Now
                            </div>
                            <div className="trashmedia2-box">
                                Restore 
                            </div>
                        </div>
                        <div className="trashmedia3">
                            <div className="trashmedia3-box">
                                <img className="thumbnailintrash"     src={Frequency}/><br/>
                            </div>
                            <div className="trashmedia3-box">
                                Image Name
                            </div>
                            <div className="trashmedia3-box">
                                Uploaded: 12/12/23
                            </div>
                            <div className="trashmedia3-box">
                                Put in trash : 12/12/23
                            </div>
                        </div>
                    </div>
                    <div className="trashmedia1">

                    </div>
                    <div className="trashmedia1">

                    </div>
                    <div className="trashmedia1">

                    </div>
                    <div className="trashmedia1" style={{marginRight: '60px'}}>

                    </div>
                </div>

           </div>
        </div>
      </div>

  
)
}
    
export default TrashContainer;