import './BrownButtons.css'

const BrownButtonReg = ({title, onClick}) => {
    return (

            <div className="brownbuttonreg" onClick={onClick}>
                <div className="titlepadding">{title}</div>
            </div>

    );
}

export default BrownButtonReg;