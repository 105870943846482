import './Headermenu.css'
import NewImage from './img/NewImage.svg'
import AboutUsImage from './img/AboutUs.svg'
import ReportsImage from './img/Reports.svg'
import PortfolioImage from './img/Portfolio.svg'
import SettingsImage from './img/Settings.svg'
import MenuHamburgerImage from './img/MenuHamburger.svg'
import SearchImage from './img/Search.svg'
import { Link } from "react-router-dom";
import {setlogout} from '../../storage/redux/slice/loginContainerSlice';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { addmediadata } from '../../storage/redux/slice/globalContainerSlice';
import {CreateSmallID} from '../../functions';
//import crypto from 'crypto';

function MyDropzone() {
    const dispatch = useDispatch();
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
  
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        // Do whatever you want with the file contents

        const buffer = reader.result;
        const binaryString = Array.from(new Uint8Array(buffer), byte => String.fromCharCode(byte)).join("");
        const base64String = btoa(binaryString);
//hash: crypto.createHash('md5').update(key).digest('hex');
        const fileid = CreateSmallID();
        const fileobject = [{
            name: file.path,
            data: base64String,
            id: fileid,
            keyid: ''
            }];

        console.log('addmediadata');
        console.log(fileobject);
        console.log();        

        dispatch(addmediadata(fileobject)) 
        }
        reader.readAsArrayBuffer(file)
      })
      
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <img alt="File Upload" title="To Upload File - Click or Drag and Drop" className="newimage" src={NewImage}/>
      </div>
    )
  }

const Headermenu = ({logoutonclick,aboutclick,reportsclick,mediaclick,accountclick}) => {
    const containerurl = useSelector((state) => state.globalContainerStore.containerurl);
    const dispatch = useDispatch();
    const navigate = useNavigate();
//<img alt="" src={SearchImage}/>
        
    return (
        <>
            
            {containerurl==="/media" && <MyDropzone />}

            <div className="dropdown">
                    <img alt="" src={MenuHamburgerImage}/>
                    <div className="dropdown-content">
                        <div className="divasa"> 
                            <div className="aligned" onClick={accountclick}>
                                <img alt=""  src={SettingsImage}/>
                                <div className="gmenutitle gmenutitle_myaccount">
                                    <span >My Account<br/>Settings</span>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="divasa">                             
                            <div className="aligned" onClick={mediaclick}>
                                <img alt=""  src={PortfolioImage}/>
                                <div className="gmenutitle gmenutitle_myportfolio" >
                                    <span>My Portfolio</span>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="divasa"> 
                            <div className="aligned" onClick={reportsclick}>
                                <img alt=""  src={ReportsImage}/>
                                <div className="gmenutitle gmenutitle_myreports" >
                                    <span >My Reports</span>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="divasa"> 
                            <div className="aligned" onClick={aboutclick}>
                                <img alt=""  src={AboutUsImage}/>
                                <div className="gmenutitle gmenutitle_about">
                                    <span>About DejaVu.art</span>
                                    <div className="bar " style={{width: '255px'}} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="divasa"> 
                            <div className="aligned" onClick={logoutonclick}>
                                <img alt=""  src={AboutUsImage}/>
                                <div className="gmenutitle gmenutitle_logout">
                                    <span>Log Out</span>
                                    <div className="bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>        
        </>
    );
}

export default Headermenu;