import { loginReducer } from "./slice/loginSlice";
import { welcomeContainerReducer } from "./slice/welcomeContainerSlice";
import { loginContainerReducer } from "./slice/loginContainerSlice";
import { subscribeContainerReducer } from "./slice/subscribeContainerSlice";
import { payContainerReducer } from "./slice/payContainerSlice";
import { exploreContainerReducer } from "./slice/exploreContainerSlice";
import { mediaContainerReducer } from "./slice/mediaContainerSlice";
import { trashContainerReducer } from "./slice/trashContainerSlice";
import { reportsContainerReducer } from "./slice/reportsContainerSlice";
import { accountContainerReducer } from "./slice/accountContainerSlice";
import { globalContainerReducer } from "./slice/globalContainerSlice";
import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../../Apis";
import { userAuthReducer } from "./slice/userAuthSlice";

const store = configureStore({
  reducer: {
    loginStore:loginReducer,
    globalContainerStore: globalContainerReducer,        
    welcomeContainerStore: welcomeContainerReducer,
    loginContainerStore: loginContainerReducer,
    subscribeContainerStore: subscribeContainerReducer,
    payContainerStore: payContainerReducer,
    exploreContainerStore: exploreContainerReducer,
    mediaContainerStore: mediaContainerReducer,
    trashContainerStore: trashContainerReducer,
    reportsContainerStore: reportsContainerReducer,
    accountContainerStore: accountContainerReducer,    
    userAuthStore: userAuthReducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware),
});

//export type RootState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof store.getState>;

export default store; 