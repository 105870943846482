import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
    loginname: '' ,
    password: '',
};

export const loginContainerSlice = createSlice({
    name: "loginContainer",
    initialState:initialState,
    reducers:{
        inputloginname: (state,action) => {
            state.loginname = action.payload;
        },
        inputpassword: (state,action) => {
            state.password = action.payload;
        },
        setlogout: (state) => {
            state.logged = 0;
        },
        setlogin: (state) => {
            state.logged = 1;
        },                
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },                

    },
});

export const {inputloginname,inputpassword,updateaction,setlogout,setlogin} =  loginContainerSlice.actions;
export const loginContainerReducer = loginContainerSlice.reducer; 


