import React, { Component } from 'react';
import Pdf1 from './WebsiteTermsofUseDejaVuArt.pdf';
import Pdf2 from './PrivacyPolicyDejaVuArt.pdf';
import Pdf3 from './CustomerTermsofServiceDejaVuArt .pdf';

class DownloadDocs extends Component {

  render() {

    return (
        <div className = "">
           <br/><br/> <br/><br/>
          <a style={{color: 'black',fontWeight: '800',fontSize: '20px' }} href = {Pdf1} target = "_blank">Website Terms Of Use</a><br/><br/>
          <a style={{color: 'black',fontWeight: '800',fontSize: '20px' }} href = {Pdf2} target = "_blank">Privacy Policy</a><br/><br/>
          <a style={{color: 'black',fontWeight: '800',fontSize: '20px'}} href = {Pdf3} target = "_blank">Terms Of Service</a>                    
        </div>
    );

  }
}

export default DownloadDocs;