import { v4 as uuid } from 'uuid';

export  function CreateSmallID() {
    const unique_id = uuid();
    return unique_id.slice(0,8)
  }


  export  function BytesTo64(buffer) {
    const binaryString = Array.from(new Uint8Array(buffer), byte => String.fromCharCode(byte)).join("");
    return btoa(binaryString);
  }
