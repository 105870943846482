import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    containeraction: '',
};

export const mediaContainerSlice = createSlice({
    name: "mediaContainer",
    initialState:initialState,
    reducers:{
        updateaction: (state,action) => {
            state.containeraction = action.payload;
        },

    },
});

export const {incupdateactionrement} =  mediaContainerSlice.actions;
export const mediaContainerReducer = mediaContainerSlice.reducer;